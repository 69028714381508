import '../App.css'
import '../Animations.css'

import {useEffect, useState} from 'react';
import {fetchFromDatabase} from "../Utilities/Utilities";
import ScreenCrowBlack from "../Images/ScreenCrowBlack.png"

function Team()
{
    const [teamMembers, setTeamMembers] = useState([]);

    //Getting the Games Members from the Database
    useEffect(() =>
    {
        fetchFromDatabase("team").then(teamMembers =>
        {
            setTeamMembers(teamMembers);
        }).catch(() => {})
    }, []);

    //Sorting based on the role
    const sortedTeamMembers = teamMembers.sort((a, b) =>
    {
        if (a.display_name < b.display_name)
            return -1;

        if (a.display_name > b.display_name)
            return 1;

        return 0;
    });

    // HTML
    return(
        <div className='page text-center'>
            <h1>Team</h1>

            <div className='flex flex-wrap justify-center gap-16'>
                {sortedTeamMembers.map((member) => (
                    <div>
                        <img src={member.image === null ? ScreenCrowBlack : member.image}
                             alt={member.display_name}
                             className='border-black border-4 w-[256px] h-[256px] rounded-full'
                        />

                        {member.display_name.split(' ').map((word, index) => (
                            <h3 key={index}>
                                {member.display_name.split(' ').length === 1 ? (
                                    <>
                                        {word}
                                        <br />
                                        <span style={{ opacity: 0 }}>Null</span>
                                    </>
                                ) : (
                                    word
                                )}
                            </h3>
                        ))}

                        <hr/>
                        <div>
                            {member.roles.map((role, index) => (
                                <p key={index} className='text-gray-400' style={{ color: role.color }} dangerouslySetInnerHTML={{ __html: role.name }} />
                            ))}
                        </div>

                    </div>
                    ))}
            </div>

            <br/>

            <p>
                Interested in joining the team? Head over to the <b><a className='hover:text-[#454545]' href='/contact'>Contact</a></b> page and email us your resume and/or portfolio!
            </p>

        </div>
    )
}

export default Team;