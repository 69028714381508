/**
 * Returns data from the ScreenCrowStudios-WebsiteAPI.
 * @param tableToGet The Table to get from the Database.
 * @return data
 */
export function fetchFromDatabase(tableToGet)
{
    const env = process.env
    return fetch(`${env.REACT_APP_WEBSITE_API_HOST}:${env.REACT_APP_WEBSITE_API_PORT}/${tableToGet}`)
        .then(response => response.json())
        .then(data =>
        {
            return data
        })
}